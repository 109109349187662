<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ userData.id ? 'Editar' : 'Nuevo' }} Usuario
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- REFERENCE -->
          <validation-provider
            #default="validationContext"
            name="ID"
          >
            <b-form-group
              label="ID"
              label-for="reference"
            >
              <b-form-input
                id="reference"
                v-model="userData.reference"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- FIRST NAME -->
          <validation-provider
            #default="validationContext"
            name="Nombre"
            rules="required"
          >
            <b-form-group
              label="Nombre"
              label-for="first_name"
            >
              <b-form-input
                id="first_name"
                v-model="userData.first_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- LAST NAME -->
          <validation-provider
            #default="validationContext"
            name="Apellido"
            rules="required"
          >
            <b-form-group
              label="Apellido"
              label-for="last_name"
            >
              <b-form-input
                id="last_name"
                v-model="userData.last_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- COUNTRY -->
          <validation-provider
            #default="validationContext"
            name="País"
            rules="required"
          >
            <b-form-group
              label="País"
              label-for="country_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.country_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countries"
                :clearable="false"
                input-id="country_id"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <template v-if="!userData.id && !userData.limits">
            <!-- CURRENCY -->
            <validation-provider
              #default="validationContext"
              name="Moneda"
              rules="required"
            >
              <b-form-group
                label="Moneda"
                label-for="currency_id"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.currency_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="currencies"
                  :clearable="false"
                  input-id="currency_id"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- METHOD -->
            <validation-provider
              #default="validationContext"
              name="Método"
              rules="required"
            >
              <b-form-group
                label="Método"
                label-for="method"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.method"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="methods"
                  :clearable="false"
                  input-id="method"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- DIARY -->
            <validation-provider
              #default="validationContext"
              name="Límite diario"
            >
              <b-form-group
                label="Límite diario"
                label-for="diary"
              >
                <b-form-input
                  id="diary"
                  v-model="userData.diary"
                  :state="getValidationState(validationContext)"
                  type="number"
                  number
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- WEEKLY -->
            <validation-provider
              #default="validationContext"
              name="Límite semanal"
            >
              <b-form-group
                label="Límite semanal"
                label-for="weekly"
              >
                <b-form-input
                  id="weekly"
                  v-model="userData.weekly"
                  :state="getValidationState(validationContext)"
                  type="number"
                  number
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- MONTHLY -->
            <validation-provider
              #default="validationContext"
              name="Límite mensual"
            >
              <b-form-group
                label="Límite mensual"
                label-for="monthly"
              >
                <b-form-input
                  id="monthly"
                  v-model="userData.monthly"
                  :state="getValidationState(validationContext)"
                  type="number"
                  number
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </template>

          <!-- OBSERVATION -->
          <validation-provider
            #default="validationContext"
            name="Observaciones"
          >
            <b-form-group
              label="Observaciones"
              label-for="observation"
            >
              <b-form-input
                id="observation"
                v-model="userData.observation"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="loading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <template v-if="loading">
                <b-spinner
                  variant="light"
                  class="mr-75"
                  small
                />
                <span>{{ userData.id ? 'Actualizando' : 'Creando' }}</span>
              </template>
              <span v-else>{{ userData.id ? 'Actualizar' : 'Crear' }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :disabled="loading"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { typesCashiers, WITHDRAW } from '@core/constants'
import useCountries from '../../countries/useCountries'
import useCurrencies from '../../currencies/useCurrencies'
import useClients from '../useClients'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  watch: {
    isSidebarActive(val) {
      if (val && this.item) {
        const country = this.item.country_id
          ? this.countries.find(c => c.id === this.item.country_id)
          : this.countries.find(c => c.value === 'CL')

        const currency = this.item.currency_id
          ? this.currencies.find(c => c.id === this.item.currency_id)
          : this.currencies.find(c => c.value === 'CLP')

        const method = this.item.method
          ? typesCashiers.find(t => t.value === this.item.method)
          : typesCashiers.find(t => t.value === WITHDRAW)

        this.userData = {
          id: this.item.id ?? '',
          country_id: country,
          reference: this.item.reference ?? '',
          first_name: this.item.first_name ?? '',
          last_name: this.item.last_name ?? '',
          observation: this.item.observation ?? '',
          // limit
          currency_id: currency,
          method,
          type: this.item.type ?? '',
          diary: this.item.diary ?? '',
          weekly: this.item.weekly ?? '',
          monthly: this.item.monthly ?? '',
          limits: this.item.limits,
        }
      } else {
        this.userData = {
          id: null,
          country_id: '',
          reference: '',
          first_name: '',
          last_name: '',
          observation: '',
          // limit
          currency_id: '',
          method: '',
          type: '',
          diary: '',
          weekly: '',
          monthly: '',
          limits: null,
        }
        this.$emit('update:item', null)
      }
    },
  },
  setup(_, { emit }) {
    const blankData = {
      id: null,
      country_id: '',
      reference: '',
      first_name: '',
      last_name: '',
      observation: '',
      // limit
      currency_id: '',
      method: '',
      type: '',
      diary: '',
      weekly: '',
      monthly: '',
      limits: null,
    }

    const userData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      userData.value = JSON.parse(JSON.stringify(blankData))
    }

    const methods = ref(typesCashiers)
    const loading = ref(false)

    const { countries } = useCountries()
    const { currencies } = useCurrencies()
    const { store, update } = useClients()

    const onSubmit = async () => {
      loading.value = true

      let data = {}

      if (!userData.id) {
        data = { ...userData.value }
        data.country_id = data.country_id?.id
        data.currency_id = data.currency_id?.id
        data.method = data.method?.value
      } else {
        data = {
          id: userData.id,
          country_id: userData.country_id?.id,
          reference: userData.reference,
          first_name: userData.first_name,
          last_name: userData.last_name,
          observation: userData.observation,
          limits: userData.limits,
        }
      }

      try {
        if (data.id) {
          await update(data.id, data)
        } else {
          await store(data)
        }

        emit('refetchData')
        emit('update:is-sidebar-active', false)
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      userData,
      loading,
      onSubmit,

      methods,
      countries,
      currencies,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
