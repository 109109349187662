<template>
  <section class="invoice-add-wrapper">
    <cashiers-limits-sidebar-user
      v-if="!onlyRead"
      :is-sidebar-active.sync="isSidebarActive"
      :item.sync="userEditable"
      @refetchData="refetchData"
    />

    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        lg="8"
      >
        <b-card
          v-if="loading || isError || currencies.length === 0"
          no-body
          class="invoice-preview-card"
        >
          <b-card-body class="invoice-padding">

            <div class="position-relative">
              <div
                v-if="!loading && isError"
                class="w-100 d-flex flex-column align-items-center justify-content-center"
                style="min-height: 200px"
              >
                <feather-icon
                  icon="AlertTriangleIcon"
                  size="30"
                />
                <span>Ocurrio un error al cargar el json de los cajeros</span>
              </div>

              <div
                v-if="loading && !isError"
                class="w-100 d-flex flex-column align-items-center justify-content-center"
                style="min-height: 200px"
              >
                <b-spinner label="Spinning" />
                <span>Cargando...</span>
              </div>

              <div
                v-if="!loading && !isError && currencies.length === 0"
                class="w-100 d-flex flex-column align-items-center justify-content-center"
                style="min-height: 200px"
              >
                <span>No se encontraron cajeros activos</span>
              </div>

            </div>
          </b-card-body>
        </b-card>

        <template v-else>
          <div
            class="accordion"
            role="tablist"
          >
            <cashiers-limits-collapse
              id="withdrawal"
              title="Cajeros de Retiro"
            >
              <p v-if="!withdrawalListFilter.length" class="text-center m-0">
                No se encontraron cajeros
              </p>

              <template v-for="(withdrawal, key) in withdrawalListFilter">
                <hr v-if="key" :key="key + '-divider'" class="my-2">

                <div :key="key" class="rounded overflow-hidden">
                  <h5 class="font-weight-bold text-center bg-primary text-white py-1 m-0">
                    {{ withdrawal.name }} ({{ withdrawal.iso }})
                  </h5>

                  <b-table
                    :items="[withdrawal]"
                    :fields="limitsHeader"
                    class="position-relative m-0 font-weight-bolder"
                    responsive
                    primary-key="id"
                    empty-text="No se encontraron límites"
                    show-empty
                    striped
                  >
                    <!-- Column: withdrawal_diary_limit -->
                    <template #cell(withdrawal_diary_limit)="data">
                      <cashiers-limits-cell-editable
                        key-edit="withdrawal_diary_limit"
                        :item="data.item"
                        :is-editable="!onlyRead"
                        :iso="withdrawal.iso"
                        @change="handleSaveChange"
                      />
                    </template>
                    <!-- Column: withdrawal_weekly_limit -->
                    <template #cell(withdrawal_weekly_limit)="data">
                      <cashiers-limits-cell-editable
                        key-edit="withdrawal_weekly_limit"
                        :item="data.item"
                        :is-editable="!onlyRead"
                        :iso="withdrawal.iso"
                        @change="handleSaveChange"
                      />
                    </template>
                    <!-- Column: withdrawal_monthly_limit -->
                    <template #cell(withdrawal_monthly_limit)="data">
                      <cashiers-limits-cell-editable
                        key-edit="withdrawal_monthly_limit"
                        :item="data.item"
                        :is-editable="!onlyRead"
                        :iso="withdrawal.iso"
                        @change="handleSaveChange"
                      />
                    </template>
                  </b-table>

                  <b-table
                    :items="withdrawal.cashiers"
                    :fields="withdrawalHeader"
                    class="position-relative m-0"
                    responsive
                    primary-key="id"
                    empty-text="No se encontraron cajeros"
                    show-empty
                    striped
                  >
                    <!-- Column: image -->
                    <template #cell(image)="data">
                      <b-img
                        :src="data.item.image"
                        height="60px"
                      />
                    </template>
                    <!-- Column: name -->
                    <template #cell(name)="data">
                      <span>
                        {{ data.item.name }}<br/>
                        <small>{{ data.item.title }}</small>
                      </span>
                    </template>
                    <!-- Column: min_limit -->
                    <template #cell(min_limit)="data">
                      <cashiers-limits-cell-editable
                        key-edit="min_limit"
                        :item="data.item"
                        :is-editable="!onlyRead"
                        :iso="withdrawal.iso"
                        @change="handleSaveChange"
                      />
                    </template>
                    <!-- Column: max_limit -->
                    <template #cell(max_limit)="data">
                      <cashiers-limits-cell-editable
                        key-edit="max_limit"
                        :item="data.item"
                        :is-editable="!onlyRead"
                        :iso="withdrawal.iso"
                        @change="handleSaveChange"
                      />
                    </template>
                  </b-table>
                </div>
              </template>
            </cashiers-limits-collapse>

            <cashiers-limits-collapse
              id="deposit"
              title="Cajero de Deposito"
            >
              <p v-if="!depositListFilter.length" class="text-center m-0">
                No se encontraron cajeros
              </p>

              <template v-for="(deposit, key) in depositListFilter">
                <hr v-if="key" :key="key + '-divider'" class="my-2">

                <div :key="key" class="rounded overflow-hidden">
                  <h5 class="font-weight-bold text-center bg-primary text-white py-1 m-0">
                    {{ deposit.name }} ({{ deposit.iso }})
                  </h5>

                  <b-table
                    :items="deposit.cashiers"
                    :fields="depositHeader"
                    class="position-relative m-0"
                    responsive
                    primary-key="id"
                    empty-text="No se encontraron cajeros"
                    show-empty
                    striped
                  >
                    <!-- Column: image -->
                    <template #cell(image)="data">
                      <b-img
                        :src="data.item.image"
                        height="60px"
                      />
                    </template>
                    <!-- Column: name -->
                    <template #cell(name)="data">
                      <span>
                        {{ data.item.name }}<br/>
                        <small>{{ data.item.title }}</small>
                      </span>
                    </template>
                    <!-- Column: min_limit -->
                    <template #cell(min_limit)="data">
                      <cashiers-limits-cell-editable
                        key-edit="min_limit"
                        :item="data.item"
                        :is-editable="!onlyRead"
                        :iso="deposit.iso"
                        @change="handleSaveChange"
                      />
                    </template>
                    <!-- Column: max_limit -->
                    <template #cell(max_limit)="data">
                      <cashiers-limits-cell-editable
                        key-edit="max_limit"
                        :item="data.item"
                        :is-editable="!onlyRead"
                        :iso="deposit.iso"
                        @change="handleSaveChange"
                      />
                    </template>
                  </b-table>
                </div>
              </template>
            </cashiers-limits-collapse>

            <cashiers-limits-collapse
              id="client-limits"
              title="Límites de clientes generales"
            >
              <b-table
                :items="generalLimitsList"
                :fields="generalLimitsHeader"
                class="position-relative m-0"
                responsive
                primary-key="id"
                empty-text="No se encontraron límites"
                show-empty
                striped
              >
                <!-- Column: diary -->
                <template #cell(diary)="data">
                  <cashiers-limits-cell-editable
                    key-edit="diary"
                    :item="data.item"
                    :is-editable="!onlyRead"
                    :iso="data.item.currency.iso"
                    @change="handleSaveChange"
                  />
                </template>
                <!-- Column: weekly -->
                <template #cell(weekly)="data">
                  <cashiers-limits-cell-editable
                    key-edit="weekly"
                    :item="data.item"
                    :is-editable="!onlyRead"
                    :iso="data.item.currency.iso"
                    @change="handleSaveChange"
                  />
                </template>
                <!-- Column: monthly -->
                <template #cell(monthly)="data">
                  <cashiers-limits-cell-editable
                    key-edit="monthly"
                    :item="data.item"
                    :is-editable="!onlyRead"
                    :iso="data.item.currency.iso"
                    @change="handleSaveChange"
                  />
                </template>
              </b-table>
            </cashiers-limits-collapse>

            <cashiers-limits-collapse
              id="vip-limits"
              title="Límites VIP"
            >
              <template #header-action>
                <b-button
                  v-if="!onlyRead"
                  id="add-vip-user"
                  variant="outline-primary"
                  size="sm"
                  block
                  @click.stop="openEditable(VIP)"
                >
                  Nuevo Usuario Vip
                </b-button>
              </template>

              <b-table
                :items="vipLimitsList"
                :fields="generalLimitsHeader"
                class="position-relative m-0"
                responsive
                primary-key="id"
                empty-text="No se encontraron límites"
                show-empty
                striped
              >
                <!-- Column: diary -->
                <template #cell(diary)="data">
                  <cashiers-limits-cell-editable
                    key-edit="diary"
                    :item="data.item"
                    :is-editable="!onlyRead"
                    :iso="data.item.currency.iso"
                    @change="handleSaveChange"
                  />
                </template>
                <!-- Column: weekly -->
                <template #cell(weekly)="data">
                  <cashiers-limits-cell-editable
                    key-edit="weekly"
                    :item="data.item"
                    :is-editable="!onlyRead"
                    :iso="data.item.currency.iso"
                    @change="handleSaveChange"
                  />
                </template>
                <!-- Column: monthly -->
                <template #cell(monthly)="data">
                  <cashiers-limits-cell-editable
                    key-edit="monthly"
                    :item="data.item"
                    :is-editable="!onlyRead"
                    :iso="data.item.currency.iso"
                    @change="handleSaveChange"
                  />
                </template>
              </b-table>
              <b-table
                :items="clientsVipListFilter"
                :fields="clientsVipHeader"
                class="position-relative m-0"
                responsive
                primary-key="id"
                empty-text="No se encontraron usuarios"
                show-empty
                striped
              >
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`row-${data.item.id}-edit-icon`"
                      icon="EditIcon"
                      size="16"
                      class="mx-1 cursor-pointer"
                      @click="onEdit(data.item, VIP)"
                    />
                    <b-tooltip
                      title="Editar usuario"
                      :target="`row-${data.item.id}-edit-icon`"
                    />

                    <div class="d-inline">
                      <feather-icon
                        v-if="!data.item.loading"
                        :id="`row-${data.item.id}-delete-icon`"
                        icon="TrashIcon"
                        size="16"
                        class="cursor-pointer"
                        @click="onDelete(data.item)"
                      />
                      <b-spinner
                        v-else
                        :id="`row-${data.item.id}-delete-icon`"
                        small
                      />
                      <b-tooltip
                        :title="data.item.loading ? 'Eliminando...' : 'Eliminar usuario'"
                        :target="`row-${data.item.id}-delete-icon`"
                      />
                    </div>
                  </div>
                </template>
              </b-table>
            </cashiers-limits-collapse>

            <cashiers-limits-collapse
              id="exclusive-limits-active"
              title="Límites exclusivos activos"
            >
              <template #header-action>
                <b-button
                  v-if="!onlyRead"
                  id="add-vip-user"
                  variant="outline-primary"
                  size="sm"
                  block
                  @click.stop="openEditable(ACTIVE_EXCLUSIVE)"
                >
                  Nuevo Usuario Exclusivo
                </b-button>
              </template>

              <b-table
                :items="clientsActiveExclusiveListFilter"
                :fields="clientsExclusiveHeader"
                class="position-relative m-0"
                responsive
                primary-key="id"
                empty-text="No se encontraron usuarios"
                show-empty
                striped
              >
                <!-- Column: diary -->
                <template #cell(diary)="data">
                  <cashiers-limits-cell-editable
                    key-edit="diary"
                    :item="data.item.limit"
                    :is-editable="!onlyRead"
                    :iso="data.item.currency.iso"
                    @change="handleSaveChange"
                  />
                </template>
                <!-- Column: weekly -->
                <template #cell(weekly)="data">
                  <cashiers-limits-cell-editable
                    key-edit="weekly"
                    :item="data.item.limit"
                    :is-editable="!onlyRead"
                    :iso="data.item.currency.iso"
                    @change="handleSaveChange"
                  />
                </template>
                <!-- Column: monthly -->
                <template #cell(monthly)="data">
                  <cashiers-limits-cell-editable
                    key-edit="monthly"
                    :item="data.item.limit"
                    :is-editable="!onlyRead"
                    :iso="data.item.currency.iso"
                    @change="handleSaveChange"
                  />
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`row-${data.item.id}-edit-icon`"
                      icon="EditIcon"
                      size="16"
                      class="mx-1 cursor-pointer"
                      @click="onEdit(data.item, ACTIVE_EXCLUSIVE)"
                    />
                    <b-tooltip
                      title="Editar usuario"
                      :target="`row-${data.item.id}-edit-icon`"
                    />

                    <div class="d-inline">
                      <feather-icon
                        v-if="!data.item.loading"
                        :id="`row-${data.item.id}-delete-icon`"
                        icon="TrashIcon"
                        size="16"
                        class="cursor-pointer"
                        @click="onDelete(data.item)"
                      />
                      <b-spinner
                        v-else
                        :id="`row-${data.item.id}-delete-icon`"
                        small
                      />
                      <b-tooltip
                        :title="data.item.loading ? 'Eliminando...' : 'Eliminar usuario'"
                        :target="`row-${data.item.id}-delete-icon`"
                      />
                    </div>
                  </div>
                </template>
              </b-table>
            </cashiers-limits-collapse>

            <cashiers-limits-collapse
              id="exclusive-limits-inactive"
              title="Límites exclusivos inactivos"
            >
              <template #header-action>
                <b-button
                  v-if="!onlyRead"
                  id="add-vip-user"
                  variant="outline-primary"
                  size="sm"
                  block
                  @click.stop="openEditable(INACTIVE_EXCLUSIVE)"
                >
                  Nuevo Usuario Exclusivo
                </b-button>
              </template>

              <b-table
                :items="clientsInactiveExclusiveListFilter"
                :fields="clientsExclusiveHeader"
                class="position-relative m-0"
                responsive
                primary-key="id"
                empty-text="No se encontraron usuarios"
                show-empty
                striped
              >
                <!-- Column: diary -->
                <template #cell(diary)="data">
                  <cashiers-limits-cell-editable
                    key-edit="diary"
                    :item="data.item.limit"
                    :is-editable="!onlyRead"
                    :iso="data.item.currency.iso"
                    @change="handleSaveChange"
                  />
                </template>
                <!-- Column: weekly -->
                <template #cell(weekly)="data">
                  <cashiers-limits-cell-editable
                    key-edit="weekly"
                    :item="data.item.limit"
                    :is-editable="!onlyRead"
                    :iso="data.item.currency.iso"
                    @change="handleSaveChange"
                  />
                </template>
                <!-- Column: monthly -->
                <template #cell(monthly)="data">
                  <cashiers-limits-cell-editable
                    key-edit="monthly"
                    :item="data.item.limit"
                    :is-editable="!onlyRead"
                    :iso="data.item.currency.iso"
                    @change="handleSaveChange"
                  />
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`row-${data.item.id}-edit-icon`"
                      icon="EditIcon"
                      size="16"
                      class="mx-1 cursor-pointer"
                      @click="onEdit(data.item, INACTIVE_EXCLUSIVE)"
                    />
                    <b-tooltip
                      title="Editar usuario"
                      :target="`row-${data.item.id}-edit-icon`"
                    />

                    <div class="d-inline">
                      <feather-icon
                        v-if="!data.item.loading"
                        :id="`row-${data.item.id}-delete-icon`"
                        icon="TrashIcon"
                        size="16"
                        class="cursor-pointer"
                        @click="onDelete(data.item)"
                      />
                      <b-spinner
                        v-else
                        :id="`row-${data.item.id}-delete-icon`"
                        small
                      />
                      <b-tooltip
                        :title="data.item.loading ? 'Eliminando...' : 'Eliminar usuario'"
                        :target="`row-${data.item.id}-delete-icon`"
                      />
                    </div>
                  </div>
                </template>
              </b-table>
            </cashiers-limits-collapse>
          </div>
        </template>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        lg="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
        <div class="content-sticky">
          <b-card>
            <div
              v-show="!hideSearch"
              class="mb-2"
            >
              <b-form-group
                label=""
                label-for="search"
              >
                <b-form-input
                  id="search"
                  ref="inputSearch"
                  v-model="search"
                  placeholder="Buscar..."
                  trim
                  @input="onSearch"
                />
              </b-form-group>
            </div>

            <div>
              <p class="font-weight-bold mb-50">
                Menú
              </p>

              <b-nav
                class="menu-list"
                vertical
              >
                <b-nav-item
                  v-for="item in asideMenu"
                  :key="item.selector"
                  @click.prevent.stop="scrollTo(item.selector)"
                >
                  <span>{{ item.label }}</span>
                  <b-badge
                    v-if="item.results !== 0"
                    variant="light"
                    class="border text-black-50"
                  >
                    {{ item.results }}
                  </b-badge>
                </b-nav-item>
              </b-nav>
            </div>
          </b-card>

          <b-card
            no-body
            class="text-secondary small p-0 d-none d-lg-block"
          >
            <p class="m-0 px-1 py-1">
              Diccionario:
            </p>

            <table class="table table-sm">
              <tr>
                <th class="px-1 text-secondary">
                  País
                </th>
                <th class="px-1 text-secondary">
                  Moneda
                </th>
                <th class="px-1 text-secondary">
                  ISO
                </th>
              </tr>
              <tr
                v-for="(item, k) in dictionary"
                :key="k"
              >
                <td class="px-1 text-secondary">
                  {{ item.country }}
                </td>
                <td class="px-1 text-secondary">
                  {{ item.currency }}
                </td>
                <td class="px-1 text-secondary">
                  {{ item.iso }}
                </td>
              </tr>
            </table>
            <p
              class="p-1 m-0 border-1 border-top"
              style="font-size: 90%;"
            >
              Todos los cajeros <strong>Dolar</strong> son de <strong>Ecuador</strong>
            </p>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardBody,
  BFormGroup,
  BFormInput,
  BSpinner,
  BNav,
  BNavItem,
  BBadge,
  BImg,
  BTable,
  BTooltip,
} from 'bootstrap-vue'

import { onMounted, ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { toast } from '@/libs/toastification-generic'
import {
  DEPOSIT,
  WITHDRAW,
  UNLIMITED,

  VIP,
  ACTIVE_EXCLUSIVE,
  INACTIVE_EXCLUSIVE,
  VIP_LIMITS_IDS,
} from '@core/constants'
import CashiersRequest from '@/@api/cashiers'
// eslint-disable-next-line import/no-cycle
import { scrollTo, formatterCurrency } from '@core/utils/utils'
import CashiersLimitsCollapse from './CashiersLimitsCollapse.vue'
import CashiersLimitsCellEditable from './CashiersLimitsCellEditable.vue'
import CashiersLimitsSidebarUser from './CashiersLimitsSidebarUser.vue'
import useClients from '../useClients'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    BSpinner,
    BNav,
    BNavItem,
    BBadge,
    BImg,
    BTable,
    BTooltip,

    CashiersLimitsCollapse,
    CashiersLimitsCellEditable,
    CashiersLimitsSidebarUser,
  },
  directives: {
    Ripple,
  },
  props: {
    hideSearch: {
      type: Boolean,
      default: false,
    },
    onlyRead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      VIP,
      ACTIVE_EXCLUSIVE,
      INACTIVE_EXCLUSIVE,
    }
  },
  methods: {
    scrollTo,
    handleFormatterCurrency: (value, iso) => {
      if (value === null || value === undefined) return UNLIMITED
      return formatterCurrency(value, iso)
    },
  },
  setup({ onlyRead }) {
    const dicType = {
      [WITHDRAW]: 'Retiro',
      [DEPOSIT]: 'Deposito',
      withdrawal_diary_limit: 'Límite diario',
      withdrawal_weekly_limit: 'Límite semanal',
      withdrawal_monthly_limit: 'Límite mensual',
      deposit_diary_limit: 'Límite diario',
      deposit_weekly_limit: 'Límite semanal',
      deposit_monthly_limit: 'Límite mensual',
      min_limit: 'Monto mínimo',
      max_limit: 'Monto máximo',
    }

    const limitsHeader = [
      { label: 'Límite diario', key: 'withdrawal_diary_limit' },
      { label: 'Límite semanal', key: 'withdrawal_weekly_limit' },
      { label: 'Límite mensual', key: 'withdrawal_monthly_limit' },
    ]
    const withdrawalHeader = [
      { label: 'Imagen', key: 'image' },
      { label: 'Cajero', key: 'name' },
      { label: 'Tipo', key: 'type', formatter: val => dicType[val] ?? val },
      { label: 'Monto mínimo', key: 'min_limit' },
      { label: 'Monto máximo', key: 'max_limit' },
    ]
    const depositHeader = [
      { label: 'Imagen', key: 'image' },
      { label: 'Cajero', key: 'name' },
      { label: 'Tipo', key: 'type', formatter: val => dicType[val] ?? val },
      { label: 'Monto mínimo', key: 'min_limit' },
      { label: 'Monto máximo', key: 'max_limit' },
    ]
    const generalLimitsHeader = [
      { label: 'Depósito/Retiro', key: 'method', formatter: val => dicType[val] ?? val },
      { label: 'Moneda', key: 'currency', formatter: val => val.iso },
      { label: 'Límite diario', key: 'diary' },
      { label: 'Límite semanal', key: 'weekly' },
      { label: 'Límite mensual', key: 'monthly' },
    ]
    const clientsVipHeader = [
      { label: 'ID', key: 'reference' },
      { label: 'Nombre', key: 'first_name' },
      { label: 'Apellido', key: 'last_name' },
      { label: 'País', key: 'country', formatter: val => val.name },
    ]
    const clientsExclusiveHeader = [
      { label: 'ID', key: 'reference' },
      { label: 'Cliente', key: 'full_name' },
      { label: 'Moneda', key: 'currency', formatter: val => val.iso },
      { label: 'Límite diario', key: 'diary' },
      { label: 'Límite semanal', key: 'weekly' },
      { label: 'Límite mensual', key: 'monthly' },
      { label: 'Observaciones', key: 'observation' },
    ]

    if (!onlyRead) {
      const actions = { label: 'Acciones', key: 'actions' }

      clientsVipHeader.push(actions)
      clientsExclusiveHeader.push(actions)
    }

    const { remove } = useClients()

    const isSidebarActive = ref(false)
    const userEditable = ref(null)

    const inputSearch = ref(null)

    const countries = ref([])
    const currencies = ref([])
    const depositList = ref([])
    const withdrawalList = ref([])
    const generalLimitsList = ref([])
    const vipLimitsList = ref([])
    const clientsVipList = ref([])
    const clientsActiveExclusiveList = ref([])
    const clientsInactiveExclusiveList = ref([])

    const currenciesFilter = ref([])
    const countriesFilter = ref([])
    const depositListFilter = ref([])
    const withdrawalListFilter = ref([])
    const clientsVipListFilter = ref([])
    const clientsActiveExclusiveListFilter = ref([])
    const clientsInactiveExclusiveListFilter = ref([])

    const loading = ref(true)
    const isError = ref(false)

    const loadingSubmit = ref(false)
    const types = ref([
      { label: 'Depósito', value: DEPOSIT },
      { label: 'Retiro', value: WITHDRAW },
    ])
    const data = ref({
      type: null,
      currency: null,
      country: null,
    })

    const dictionary = ref([])
    const search = ref('')

    const asideMenu = ref({
      withdrawal: { selector: '#withdrawal', label: 'Cajeros de Retiro', results: 0 },
      deposit: { selector: '#deposit', label: 'Cajero de Deposito', results: 0 },
      'client-limits': { selector: '#client-limits', label: 'Límites de clientes generales', results: 0 },
      'vip-limits': { selector: '#vip-limits', label: 'Límites VIP', results: 0 },
      'exclusive-limits-active': { selector: '#exclusive-limits-active', label: 'Límites exclusivos activos', results: 0 },
      'exclusive-limits-inactive': { selector: '#exclusive-limits-inactive', label: 'Límites exclusivos inactivos', results: 0 },
    })

    // Si no fuera porque este diccionario esta en el plugin, todo fuera mas fácil
    const dic = {
      CL: 'CLP',
      PE: 'PEN',
      BR: 'BRL',
      EC: 'USD', // Todos los cajeros USD son de Ecuador
      MX: 'MXN',
      GLOBAL: 'EUR',
    }

    const loadDictionary = () => {
      const newDic = {}

      let k = -1
      while (++k < countries.value.length) {
        const country = countries.value[k]
        let j = -1
        while (++j < currencies.value.length) {
          const currency = currencies.value[j]

          if (dic[country.value]) {
            newDic[country.value] = {
              country: country.label,
              currency: currency.value === dic[country.value] ? currency.label : newDic[country.value]?.currency ?? '',
              iso: currency.value === dic[country.value] ? currency.value : dic[country.value],
            }
          }
        }
      }

      dictionary.value = Object.values(newDic)
    }

    const countryAdapter = country => ({
      id: country.id,
      value: country.iso?.toUpperCase() || country.id,
      label: country.name,
    })

    const currencyAdapter = currency => ({
      id: currency.id,
      value: currency.iso?.toUpperCase() || currency.id,
      label: currency.name,
    })

    const onSearch = value => {
      if (!value) {
        currenciesFilter.value = currencies.value
        countriesFilter.value = countries.value
        withdrawalListFilter.value = withdrawalList.value
        depositListFilter.value = depositList.value
        clientsVipListFilter.value = clientsVipList.value
        clientsActiveExclusiveListFilter.value = clientsActiveExclusiveList.value
        clientsInactiveExclusiveListFilter.value = clientsInactiveExclusiveList.value

        Object.keys(asideMenu.value).forEach(key => {
          asideMenu.value[key] = { ...asideMenu.value[key], results: 0 }
        })

        return
      }

      const q = value.toLowerCase()

      // filtrar cajeros por nombre
      const filterCashiers = item => ({
        ...item,
        cashiers: item.cashiers.filter(cashier => cashier.name.toLowerCase().includes(q)
          || cashier.title?.toLowerCase().includes(q)
          || String(cashier.min_limit)?.toLowerCase().includes(q)
          || String(cashier.max_limit)?.toLowerCase().includes(q)),
      })
      // filtrar listado de monedas si tiene cajeros en su listado
      const filterByCurrency = list => list.map(filterCashiers).filter(i => i.cashiers.length)

      withdrawalListFilter.value = filterByCurrency(withdrawalList.value)
      depositListFilter.value = filterByCurrency(depositList.value)

      // contar cajeros dentro del lispado pasado
      const countCashiers = list => list.reduce((val, current) => val + current.cashiers.length, 0)

      asideMenu.value.withdrawal = { ...asideMenu.value.withdrawal, results: countCashiers(withdrawalListFilter.value) }
      asideMenu.value.deposit = { ...asideMenu.value.deposit, results: countCashiers(depositListFilter.value) }

      // filtrar user limits
      const filterClient = list => list.filter(user => user.reference?.toLowerCase().includes(q)
        || user.first_name?.toLowerCase().includes(q)
        || user.last_name?.toLowerCase().includes(q)
        || String(user.limit?.diary)?.includes(q)
        || String(user.limit?.weekly)?.includes(q)
        || String(user.limit?.monthly)?.includes(q))
      clientsVipListFilter.value = filterClient(clientsVipList.value)
      clientsActiveExclusiveListFilter.value = filterClient(clientsActiveExclusiveList.value)
      clientsInactiveExclusiveListFilter.value = filterClient(clientsInactiveExclusiveList.value)

      // contar los clientes
      asideMenu.value['vip-limits'] = { ...asideMenu.value['vip-limits'], results: clientsVipListFilter.value.length }
      asideMenu.value['exclusive-limits-active'] = { ...asideMenu.value['exclusive-limits-active'], results: clientsActiveExclusiveListFilter.value.length }
      asideMenu.value['exclusive-limits-inactive'] = { ...asideMenu.value['exclusive-limits-inactive'], results: clientsInactiveExclusiveListFilter.value.length }
    }

    const init = async (withLoading = true) => {
      loading.value = withLoading
      isError.value = false

      try {
        const resp = await (onlyRead
          ? CashiersRequest.limits()
          : CashiersRequest.limitsAdmin())

        const {
          success,
          deposit,
          withdrawal,
          currencies: _currencies,
          countries: _countries,
          generalLimits,
          vipLimits,
          clientsVipLimit,
          clientsActiveExclusiveLimit,
          clientsInactiveExclusiveLimit,
        } = resp.data

        if (!success) {
          throw new Error('Endpoint error')
        }

        currencies.value = _currencies.map(currencyAdapter)
        countries.value = _countries.map(countryAdapter)

        // Cashiers
        const mapCashier = c => ({ ...c, item_type: 'cashier' })
        depositList.value = deposit.map(d => ({
          ...d,
          item_type: 'currency',
          cashiers: d.cashiers.map(mapCashier),
        }))
        withdrawalList.value = withdrawal.map(w => ({
          ...w,
          item_type: 'currency',
          cashiers: w.cashiers.map(mapCashier),
        }))

        // Limits
        const mapLimit = l => ({ ...l, item_type: 'limit' })
        generalLimitsList.value = generalLimits.map(mapLimit)
        vipLimitsList.value = vipLimits.map(mapLimit)

        // Clients
        const mapUser = u => ({ ...u, loading: false })
        const mapUserExclusive = user => {
          const limit = { ...user.limits[0] }
          limit.item_type = 'limit'

          return {
            ...user,
            loading: false,
            full_name: `${user.first_name} ${user.last_name}`,
            item_type: 'limit',
            currency: limit.currency,
            limit,
          }
        }
        clientsVipList.value = clientsVipLimit.map(mapUser)
        clientsActiveExclusiveList.value = clientsActiveExclusiveLimit.map(mapUserExclusive)
        clientsInactiveExclusiveList.value = clientsInactiveExclusiveLimit.map(mapUserExclusive)

        onSearch(search.value)

        if (withLoading) loadDictionary()
      } catch (error) {
        console.log(error)

        toast({
          title: 'Ups! ocurrio un error al cargar',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        })

        isError.value = withLoading
      } finally {
        loading.value = false
      }
    }

    const refetchData = async () => {
      await init(false)
    }

    /**
     * @param {{event, key: string, item, value: string, iso: string}} params
     */
    const handleSaveChange = async params => {
      const {
        event, key, item, iso, value,
      } = params

      event.target.setAttribute('contenteditable', 'false')
      event.target.classList.add('text-muted')

      try {
        const form = {
          id: item.id,
          key,
          value,
        }
        // await new Promise(resolve => setTimeout(resolve, 1500))

        if (item.item_type === 'cashier') await CashiersRequest.saveLimitCashier(form)
        if (item.item_type === 'currency') await CashiersRequest.saveLimitCurrency(form)
        if (item.item_type === 'limit') await CashiersRequest.saveLimitUser(form)

        refetchData(false)
      } catch (e) {
        console.log('Error', e)

        // si ocurre un error, se devuelve el valor anterior
        const ovalue = event.target.getAttribute('data-ovalue')
        event.target.innerText = ovalue !== '' ? formatterCurrency(ovalue, iso) : UNLIMITED

        toast({
          title: `Ups! error al guardar el ${dicType[key] ?? key}`,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        })
      } finally {
        event.target.setAttribute('contenteditable', 'true')
        event.target.classList.remove('text-muted')
      }
    }

    const openEditable = type => {
      userEditable.value = {
        type,
        limits: type === VIP ? VIP_LIMITS_IDS : null,
      }

      isSidebarActive.value = !isSidebarActive.value
    }

    const onEdit = (item, type) => {
      userEditable.value = {
        ...item,
        type,
        limits: type === VIP ? VIP_LIMITS_IDS : item.limits?.map(l => l.id),
      }

      isSidebarActive.value = !isSidebarActive.value
    }

    async function onDelete(item) {
      try {
        const result = await this.$swal({
          title: '¿Estas seguro?',
          text: '¡No podrás revertir esta acción!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '¡Sí, bórralo!',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (result.value) {
          // eslint-disable-next-line no-param-reassign
          item.loading = true
          await remove(item.id)
          // eslint-disable-next-line no-param-reassign
          item.loading = false
          refetchData(false)

          this.$swal({
            icon: 'success',
            title: '¡Borrado!',
            text: 'El usuario ha sido borrado',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      } catch (error) {
        console.log(error)
      }
    }

    onMounted(init)

    return {
      loading,
      isError,
      isSidebarActive,
      userEditable,

      loadingSubmit,
      data,

      limitsHeader,
      withdrawalHeader,
      depositHeader,
      generalLimitsHeader,
      clientsVipHeader,
      clientsExclusiveHeader,

      generalLimitsList,
      vipLimitsList,

      currencies,
      countries,
      types,

      dictionary,

      asideMenu,

      inputSearch,
      search,
      onSearch,
      currenciesFilter,
      countriesFilter,
      depositListFilter,
      withdrawalListFilter,
      clientsVipListFilter,
      clientsActiveExclusiveListFilter,
      clientsInactiveExclusiveListFilter,

      refetchData,
      handleSaveChange,
      openEditable,
      onEdit,
      onDelete,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.table.b-table {
  tr > td {
    position: relative;
  }

  .action-dblclic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

.card-cashier {
  max-width: 100%;
  height: calc(100% - 1.5rem);

  > img {
    aspect-ratio: 18/9;
    padding: 10px;
    background: white;
  }

  > .card-body {
    padding: 0.5rem;

    .card-title {
      margin-bottom: 1rem;
    }
  }
}

.invoice-add {
  flex-direction: column-reverse;
}
@media (min-width: 992px) {
  .invoice-add {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .content-sticky {
    position: sticky;
    top: 108px;
  }
}

.menu-list {
  --text-color: #{$secondary};

  .dark-layout & {
    --text-color: #{$theme-dark-body-color};
  }

  li a {
    color: var(--text-color);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover, &:focus, &.active {
      color: $primary;
    }
  }
}
</style>
