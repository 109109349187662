<template>
  <b-card
    no-body
    class="rounded"
  >
    <div
      :id="id"
      style="margin-top: -110px; margin-bottom: 110px"
    />

    <b-card-body class="px-2 py-1">
      <b-card
        class="m-0"
        no-body
      >
        <b-card-header
          header-tag="header"
          class="px-0 py-50"
          role="tab"
          @click="toggle"
        >
          <h4 class="text-primary m-0">
            {{ title }}
          </h4>
          <div class="d-flex gap-1">
            <slot name="header-action" />
            <b-button
              variant="outline-primary"
              size="sm"
            >
              <feather-icon
                :class="`${visible ? 'rotate-180' : ''}`"
                icon="ChevronDownIcon"
                size="16"
                style="transition: transform 300ms ease-in-out"
              />
            </b-button>
          </div>
        </b-card-header>

        <b-collapse
          :id="`accordion-${id}`"
          v-model="visible"
          :accordion="`accordion-${id}`"
          role="tabpanel"
        >
          <b-card-body class="py-1 px-50">
            <slot />
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BButton,
  BCollapse,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BButton,
    BCollapse,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    const visible = ref(true)

    const toggle = () => {
      visible.value = !visible.value
    }

    return {
      visible,
      toggle,
    }
  },
}
</script>
