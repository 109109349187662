import ClientsRequest from '@/@api/clients'
import { ref, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debouncedWatch } from '@vueuse/core'

const list = ref([])

export default function useClients() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    // { label: 'Imagen', key: 'image' },
    {
      label: 'Cajero',
      key: 'name',
      sortable: true,
      thStyle: { width: '100%' },
    },
    { label: 'Selector', key: 'selector', sortable: true },
    { label: 'Paises', key: 'countries', sortable: true },
    { label: 'Monedas', key: 'currencies', sortable: true },
    { label: 'Tipo', key: 'type', sortable: true },
    { label: 'Activo', key: 'status', sortable: true },
    { label: 'Acciones', key: 'actions' },
  ]
  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const loadingFilter = ref(false)
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  debouncedWatch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  }, { debounce: 400 })

  const fetchList = (ctx, callback) => {
    loadingFilter.value = true
    ClientsRequest.list({
      params: {
        q: searchQuery.value,
        paginate: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      },
    })
      .then(response => {
        const { data, total } = response.data.data
        callback(data.map(cashier => ({ ...cashier, loading: false })))
        totalList.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching cashiers list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        loadingFilter.value = false
      })
  }

  async function fetchListSelector(isMemo = false) {
    if (isMemo && list.value.length) {
      return Promise.resolve(list.value)
    }

    try {
      const { data } = await ClientsRequest.select()

      const items = data.data.map(i => ({
        id: i.id,
        value: i.id,
        label: i.name,
      }))

      list.value = items

      return items
    } catch (error) {
      throw new Error(error)
    }
  }

  async function store(body) {
    try {
      const { data } = await ClientsRequest.store(body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function update(id, body) {
    try {
      const { data } = await ClientsRequest.update(id, body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function remove(id) {
    try {
      const { data } = await ClientsRequest.destroy(id)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // fetchListSelector(true).then(items => {
  //   list.value = items
  // })

  return {
    list,
    fetchList,
    fetchListSelector,
    tableColumns,
    perPage,
    currentPage,
    totalList,
    dataMeta,
    perPageOptions,
    searchQuery,
    loadingFilter,
    sortBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    store,
    update,
    remove,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
