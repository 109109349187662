<template>
  <b-container
    class="px-md-3 py-2"
    fluid
  >
    <b-card
      class="card-navbar rounded"
      no-body
    >
      <b-navbar
        class="flex-row-reverse flex-md-row rounded"
        toggleable="md"
      >
        <b-navbar-toggle
          target="nav-collapse"
          class="d-flex d-md-none align-items-center"
        >
          <template #default="{ expanded }">
            <feather-icon
              v-if="expanded"
              icon="XIcon"
              size="24"
            />
            <feather-icon
              v-else
              icon="MenuIcon"
              size="24"
            />
          </template>
        </b-navbar-toggle>

        <b-navbar-brand>
          <div class="brand-logo">
            <vuexy-logo />
            <h2 class="brand-text text-primary ml-1">
              Estelarbet
            </h2>
          </div>
        </b-navbar-brand>

        <b-collapse
          id="nav-collapse"
          is-nav
        >
          <b-navbar-nav class="w-100">
            <b-form-group
              label=""
              label-for="search"
              class="w-100 my-50 ml-md-3"
            >
              <b-form-input
                id="search"
                v-model="search"
                placeholder="Buscar..."
                trim
                @input="onSearch"
              />
            </b-form-group>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-card>

    <b-row>
      <b-col cols="12">
        <cashiers-limits-view
          ref="viewRef"
          hide-search
          only-read
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BNavbar,
  BNavbarToggle,
  BNavbarBrand,
  BNavbarNav,
  BCollapse,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import CashiersLimitsView from './components/CashiersLimitsView.vue'

export default {
  components: {
    CashiersLimitsView,
    BContainer,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BNavbar,
    BNavbarToggle,
    BNavbarBrand,
    BNavbarNav,
    BCollapse,
    VuexyLogo,
  },
  setup() {
    const viewRef = ref(null)
    const search = ref('')

    const onSearch = value => {
      if (viewRef.value) {
        viewRef.value.onSearch(value)
      }
    }

    return {
      viewRef,
      search,
      onSearch,
    }
  },
}
</script>

<style lang="scss" scoped>
.card-navbar {
  position: sticky;
  top: 1.5rem;
  z-index: 1;
  margin-bottom: 0 !important;

  @media (min-width: 768px) {
    margin-bottom: 2rem !important;
  }

  &:after {
    content: '';
    position: absolute;
    top: -50%;
    left: -5%;
    z-index: -1;
    width: 110%;
    height: 100%;
    background: linear-gradient(180deg, rgba(248, 248, 248, 0.95) 44%, rgba(248, 248, 248, 0.46) 73%, rgba(255, 255, 255, 0));
  }
}

.dark-layout {
  .navbar-light {
    background: #283046;
  }

  .card-navbar:after {
    background: linear-gradient(180deg, rgb(22, 29, 49, 0.95) 44%, rgb(22, 29, 49, 0.46) 73%, rgb(22, 29, 49, 0));
  }
}

.brand-logo {
  display: flex;
  justify-content: center;

  > img {
    margin: 0 !important;
  }

  .brand-text {
    font-weight: 600;
    margin-top: 0.5rem !important;
    margin-bottom: 0;
  }
}
</style>
