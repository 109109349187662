<template>
  <div
    :contenteditable="isEditable"
    :class="isEditable ? 'editable' : ''"
    @focus="onFocus"
    @blur="onBlur"
    @keydown="onKeydown"
  >
    {{ handleFormatterCurrency(item[keyEdit], iso) }}
  </div>
</template>

<script>
import { UNLIMITED } from '@/@core/constants'
import { formatterCurrency } from '@core/utils/utils'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    keyEdit: String,
    // eslint-disable-next-line vue/require-default-prop
    item: Object,
    iso: {
      type: String,
      default: 'CLP',
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  methods: {
    handleFormatterCurrency: (value, iso) => {
      if (value === null || value === undefined) return UNLIMITED
      return formatterCurrency(value, iso)
    },
  },
  setup({
    isEditable, iso, keyEdit, item,
  }, { emit }) {
    const onFocus = e => {
      if (!isEditable) return

      const event = e

      const value = String(event.target.innerText).replace(/\D/g, '')

      event.target.setAttribute('data-ovalue', value)
      event.target.innerText = value || ''
    }

    const onBlur = e => {
      if (!isEditable) return

      const event = e

      const valtxt = String(event.target.innerText).replace(/\D/g, '')
      const value = valtxt ? formatterCurrency(valtxt, iso).replace(/\D/g, '') : ''

      event.target.innerText = value !== '' ? formatterCurrency(value, iso) : UNLIMITED

      const ovalue = event.target.getAttribute('data-ovalue')

      if (ovalue !== value) {
        emit('change', {
          event, key: keyEdit, item, value, iso,
        })
      }
    }

    const onKeydown = e => {
      if (!isEditable) {
        e.preventDefault()
        e.stopPropagation()
        return
      }

      // [Backspace, Tab, Enter, ArrowLeft, ArrowRight]
      const keys = [8, 9, 13, 37, 39]

      if ((e.keyCode < 48 || e.keyCode > 57) && !keys.includes(e.keyCode)) {
        e.preventDefault()
        e.stopPropagation()
      }

      // if keydown is enter, fire blur to save
      if (e.keyCode === 13) e.target.blur()
    }

    return {
      onFocus,
      onBlur,
      onKeydown,
    }
  },
}
</script>

<style scope>
.editable {
  position: relative;
  padding: 3px 6px;
  left: -6px;
}
.editable:hover {
  border-bottom: 1px dashed var(--primary);
  margin-bottom: -1px;
  cursor: cell;
}
.editable:focus {
  border-bottom: 0;
  margin-bottom: 0;
  cursor: inherit;
}
</style>
